// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_SIK5K{display:flex;flex-direction:column;height:100%;justify-content:space-between}.filters_XdQl1{align-items:flex-end;display:flex;gap:1rem;width:100%}.sticky_trUnc{bottom:1rem;display:flex;flex-direction:row;margin:0 auto;position:sticky;width:-moz-fit-content;width:fit-content;z-index:1}.filterMenu_kKJ6h{display:flex;flex-direction:column}.headerRight_wCWpP{flex:1}.filterToggleItem_\\+AEZd{cursor:pointer;padding:.5rem}.filterToggleItem_\\+AEZd:hover{background-color:rgba(0,0,0,.2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_SIK5K",
	"filters": "filters_XdQl1",
	"sticky": "sticky_trUnc",
	"filterMenu": "filterMenu_kKJ6h",
	"headerRight": "headerRight_wCWpP",
	"filterToggleItem": "filterToggleItem_+AEZd"
};
module.exports = ___CSS_LOADER_EXPORT___;
